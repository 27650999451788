import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { GET, POST, API_URL } from './_config'
import { BrowserRouter } from 'react-router-dom';
import { register, unregister } from './registerServiceWorker';
//import reportWebVitals from './reportWebVitals';

const BUID = parseInt(atob(window.location.pathname.split('/')[1]));
const token = localStorage.getItem('resto-loyalty-token');

const render = () => {
	ReactDOM.render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
		document.getElementById('root')
	);
}

const getStoreConfig = () => {
	return new Promise((resolve, reject) => {
		let store_config_key = 'resto-store-config-' + BUID;
		let storeConfig = sessionStorage.getItem(store_config_key);
		if (storeConfig) {
			resolve(true);
		} else {
			GET(API_URL.STORE_CONFIG, { fkbusinessunitid: BUID },).then((resp: any) => {
				sessionStorage.setItem(store_config_key, JSON.stringify(resp.data));
				resolve(true);
			}).catch((err: any) => {
				sessionStorage.removeItem(store_config_key);
				reject(false)
			})
		}
	});
}

const getStoreSettings = () => {
	return new Promise((resolve, reject) => {
		let store_settings_key = 'resto-store-settings-' + BUID;
		let storeSettings = sessionStorage.getItem(store_settings_key);
		if (storeSettings) {
			resolve(true);
		} else {
			GET(API_URL.STORE_SETTINGS, { BusinessUnitID: BUID },).then((resp: any) => {
				sessionStorage.setItem(store_settings_key, JSON.stringify(resp.data.LoyaltySettingsGetList[0]));
				resolve(true);
			}).catch((err: any) => {
				sessionStorage.removeItem(store_settings_key);
				reject(false)
			})
		}
	});
}

const getStoreData = () => {
	return new Promise((resolve, reject) => {
		let store_key = 'resto-store-' + BUID;
		let storeConfig = sessionStorage.getItem(store_key);
		if (storeConfig) {
			resolve(true);
		} else {
			GET(API_URL.STORE_DETAILS, { fkbusinessunitid: BUID },).then((resp: any) => {
				sessionStorage.setItem(store_key, JSON.stringify(resp.data));
				resolve(true);
			}).catch((err: any) => {
				sessionStorage.removeItem(store_key);
				reject(false)
			})
		}
	});
}






const getStoreDetails = () => {
	return new Promise((resolve, reject) => {
		if (!isNaN(BUID)) {
			sessionStorage.setItem('resto-loyalty-buid', BUID.toString());
			getStoreData().then((resp: any) => {
				getStoreConfig().then(() => {
					getStoreSettings().then(resolve).catch(reject)
				}).catch(reject)
			}).catch((err: any) => { })
		} else {
			reject(false)
		}
	})
}


if (token) {
	getStoreDetails().then((resp: any) => { render() }).catch((err) => { render() })
} else {
	GET(API_URL.AUTH, {}, {
		Authorization: `Bearer APIUser:wXPocq0fG24=`,
	}).then((resp: any) => {
		localStorage.setItem('resto-loyalty-token', resp.headers.token);
		getStoreDetails().then((resp: any) => { render() }).catch((err) => { render() })
	}).catch((err: any) => {
		console.log('Err =>', err);
		render();
	})
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

/* if (process.env.NODE_ENV != 'development') {
	register();
}
 */