import axios from 'axios';
import { settings } from 'cluster';
export const API_URL = {
    AUTH: `/Authenticate/Authenticate`,
    USER_LOGIN: `/LoyaltyProgram/LoyaltyCrewCheckLogin`,
    STORE_DETAILS: `/LoyaltyProgram/GetLoyaltyBranding`,
    STORE_CONFIG: `/LoyaltyProgram/GetBundleStoreCreditSettings`,
    STORE_SETTINGS: `/LoyaltyCrewPortalStore/GetLoyaltySettings`,
    CHECK_USER: `/LoyaltyProgram/CheckUser`,
    ADD_USER: `/LoyaltyProgram/AddUser`,
    GET_BUNDLE_LIST: `/LoyaltyProgram/GetBundleList`,
    ADD_BUNDLE: `/LoyaltyProgram/AddBundle`,
    ADD_CREDIT: `/LoyaltyProgram/AddCredit`,
    ADD_STAMP: `/LoyaltyProgram/AddStamps`,
    REDEEM_CREDIT: `/LoyaltyProgram/RedeemCredit`,
    REDEEM_REWARDS: `/LoyaltyProgram/RedeemRewards`,
    REDEEM_BUNDLES: `/LoyaltyProgram/RedeemBundle`,
    GET_HISTORY_LIST: `/LoyaltyProgram/GetHistoryList`,
    GET_CUSTOMER: `/LoyaltyCrewPortalStore/GetLoyaltyCrewCustomerLoyaltyCard`,
    CALCULATE_STAMP_COIN: `/LoyaltyCrewPortalTransaction/GetCrewCoinStamp`,
    CALCULATE_GCOIN_AMOUNT: `/LoyaltyCrewPortalTransaction/GetRedemptionAmount`,
    REDEEM_GCOIN: `/LoyaltyCrewPortalTransaction/AddGlobalCoinRedemption`,
    GET_BOARD_DATA: `/LoyaltyProgram/UserStampRewardsDetails`,
}

export const call = (method: any, url: any, data: any = {}, headers: any = { Token: localStorage.getItem('resto-loyalty-token') }) => {
    return new Promise((resolve, reject) => {
        let settings: any = {
            method: method,
            url: process.env.REACT_APP_API_DOMAIN + url,
            headers: headers
        }
        if (method.toLowerCase() === 'get') {
            settings.params = data;
        } else {
            settings.data = data;
        }
        axios(settings).then(resp => {
            resolve(resp);
        }).catch((err: any) => {
            if (err.response.status === 401) {
                localStorage.removeItem('resto-loyalty-token');
                window.location.reload();
            }
            reject(err)
        })
    })

}
export const GET = (url: any, data: any = {}, headers: any = { Token: localStorage.getItem('resto-loyalty-token') }) => {
    return call('GET', url, data, headers);
}

export const POST = (url: any, data: any = {}, headers: any = { Token: localStorage.getItem('resto-loyalty-token') }) => {
    return call('POST', url, data, headers);
}
