import React, { useEffect, useState } from 'react';
import Header from '../../component/header';
import Footer from '../../component/footer';
import UserFinder from '../../component/userFinder';
import Modal from '../../component/modal';
import { GET, POST, API_URL } from '../../_config'
export default function Bundles() {
    const [newUser, setNewUser] = useState<boolean>(false);
    const [number, setNumber] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [bundles, setBundles] = useState<any>(null);
    const [bundle, setBundle] = useState<any>(null);
    const [modalStatus, setModalStatus] = useState<any>('');
    const [modal, setModal] = useState<boolean>(false);
    const modalClose = () => {
        setBundle(null);
        setModal(false);
    }

    const BUID = sessionStorage.getItem('resto-loyalty-buid');

    const onUser = (user: any, number: any) => {
        console.log("User finder =>", user)
        if (user) {
            setNewUser(user.NewUser);
            setNumber(number);
            setUser(user);
            if (!user.NewUser) {
                getBundles();
            }
        } else {
            setNewUser(false);
            setNumber(null);
            setUser(null);
        }
    }

    const addUser = () => {
        setNewUser(false);
        POST(API_URL.ADD_USER, {
            loyaltyuserid: 0,//_this.user.id,
            fkbusinessunitid: BUID,
            mobile: number,
            name: null,
            email: null,
            dob: null
        }).then((resp: any) => {
            setUser(resp.data);
            getBundles();
        }).catch((err: any) => {

        })
    }

    const getBundles = () => {
        GET(API_URL.GET_BUNDLE_LIST, { "fkbusinessunitid": BUID }).then((resp: any) => {
            console.log('Bundle list =>', resp.data);
            setBundles(resp.data);
        }).catch((err: any) => {

        })
    }
    const getTitle = (_bundle: any) => {
        if (_bundle === bundle) {
            switch (modalStatus) {
                case 'working': return _bundle.BundleName;
                case 'success': return _bundle.BundleName + ' Added Successfully.';
                case 'error': return 'Something Wrong! Please try after sometimes.';
                default:
                    return _bundle.BundleName;
            }
        } else {
            return _bundle.BundleName;
        }

    }
    useEffect(() => {
        if (bundle) {
            setModal(true);
        }
    }, [bundle])

    return (
        <React.Fragment>
            <Modal
                show={modal}
                onHide={modalClose}
                data={bundle}
                customer={user}
                type="addBundles"
                max={10}
                status={setModalStatus}
            />
            <div className="fixed-background-bg loyalty-bg-crew"></div>
            <Header />
            <section className="view-area">
                <h2>BUNDLES</h2>
                <UserFinder onUser={onUser} />

                {newUser ?
                    <div className="newuser card" onClick={addUser}>
                        <div className="number">+919830505538</div>
                        <div className="label">New User</div>
                    </div>
                    : null}
                <div className="section">
                    {bundles?.map((_bundle: any, i: any) => (

                        <div className={"card bundle " + (_bundle === bundle ? modalStatus : '')} key={i} onClick={() => { setBundle(_bundle) }}>
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" width="32px" height="32px"><path style={{ 'fill': '#FFE17D' }} d="M248.768,1.654L19.499,112.336c-2.875,1.388-4.701,4.298-4.701,7.49v272.348  c0,3.192,1.827,6.102,4.701,7.49l229.269,110.682c4.569,2.206,9.895,2.206,14.464,0l229.269-110.682  c2.875-1.388,4.701-4.298,4.701-7.49V119.826c0-3.192-1.827-6.102-4.701-7.49L263.232,1.654  C258.663-0.551,253.337-0.551,248.768,1.654z"></path><path style={{ 'fill': '#FFC350' }} d="M16.181,115.273c-0.874,1.331-1.383,2.898-1.383,4.553v272.348c0,3.192,1.827,6.102,4.701,7.49  l229.269,110.681c2.284,1.103,4.758,1.654,7.232,1.654V231.048L16.181,115.273z"></path><path style={{ 'fill': '#FFD164' }} d="M495.819,115.273c0.874,1.331,1.383,2.898,1.383,4.553v272.348c0,3.192-1.827,6.102-4.701,7.49  L263.232,510.345C260.948,511.448,258.474,512,256,512V231.048L495.819,115.273z"></path><path style={{ 'fill': '#FFE17D' }} d="M83.407,377.657c-1.21,0-2.445-0.268-3.606-0.828L44.404,359.74  c-4.142-1.998-5.872-6.969-3.874-11.103c1.99-4.134,6.977-5.856,11.103-3.874l35.397,17.09c4.142,1.998,5.872,6.969,3.874,11.103  C89.475,375.927,86.502,377.657,83.407,377.657z"></path><path style={{ 'fill': '#FFF6D8' }} d="M115.986,356.45c-1.21,0-2.445-0.268-3.606-0.828l-67.928-32.79  c-4.134-1.998-5.872-6.969-3.874-11.103c1.99-4.126,6.985-5.856,11.103-3.874l67.927,32.79c4.134,1.998,5.872,6.969,3.874,11.103  C122.054,354.719,119.081,356.45,115.986,356.45z"></path><polygon style={{ 'fill': '#FF8087' }} points="414.549,154.373 173.887,38.11 110.447,68.86 351.649,185.303 "></polygon><path style={{ 'fill': '#E6646E' }} d="M351.649,185.303v90.207c0,3.066,3.205,5.078,5.967,3.745l52.232-25.215  c2.875-1.388,4.701-4.298,4.701-7.49v-92.176L351.649,185.303z"></path></svg>
                            </div>
                            <div className="label">
                                <h2 className="title">{getTitle(_bundle)}</h2>
                            </div>
                            <div className="quantity">${_bundle.Price}</div>
                        </div>
                    ))}
                </div>

            </section>
            <Footer />
        </React.Fragment>
    );
}