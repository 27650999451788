import React, { useEffect, useState } from 'react';
import Header from '../../component/header';
import Footer from '../../component/footer';
import UserFinder from '../../component/userFinder';
import { GET, POST, API_URL } from '../../_config'

import { IoIosTimer } from 'react-icons/io';
import { Redirect } from 'react-router-dom';
export default function History() {
    const [number, setNumber] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [history, setHistory] = useState<any>(null);
    const [view, setView] = useState<boolean>(false);

    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const BUID = sessionStorage.getItem('resto-loyalty-buid');
    const USERID = sessionStorage.getItem('loyalty-user-id-' + BUID);

    const onUser = (user: any, number: any) => {
        if (user) {
            setNumber(number);
            setUser(user);
            getHistory(user.ID)
        } else {
            getHistory(null)
            setNumber(null);
            setUser(null);
        }
    }

    const getHistory = (ID: any) => {
        GET(API_URL.GET_HISTORY_LIST, {
            "fkbusinessunitid": BUID,
            "loyaltyuserid": ID,
            "crewloginid": USERID,
            "pageno": 1,
            r: (new Date()).getTime()
        }).then((resp: any) => {
            setHistory(resp.data);
            console.log(resp.data)
        })
    }
    const getFormateddate = (datetime: any) => {
        let dt: any = new Date(datetime);
        return dt.getDate() + ' ' + month[dt.getMonth()] + ' ' + dt.getFullYear()
    }

    const viewCustomer = (_history: any) => {
        sessionStorage.setItem('current-user-number' + BUID, _history.Mobile)
        setView(true)
    }



    useEffect(() => {
        getHistory(null);
    }, [])



    return (
        <React.Fragment>
            {view ? <Redirect to={`/${btoa(BUID ? BUID : '')}/crew`} /> : null}
            <div className="fixed-background-bg loyalty-bg-crew"></div>
            <Header />
            <section className="view-area">
                <h2>HISTORY</h2>
                <UserFinder onUser={onUser} />


                <div className="view-area">
                    {history?.map((_history: any, i: any) => (
                        <div className="history-block" key={i}>
                            <div className="top">
                                <div className="number">{_history.Mobile}</div>
                                <div className="action">{_history.Descripttion}</div>
                                <div className="time">
                                    <IoIosTimer className="icon" />
                                    <div className="text">{getFormateddate(_history.Created)}</div>
                                </div>
                            </div>
                            <div className="customer" onClick={() => { viewCustomer(_history) }}>
                                View Customer
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}