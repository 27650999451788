import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ImUsers } from 'react-icons/im';
import { MdHistory, MdKeyboardArrowDown } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import { IoMdGift } from 'react-icons/io';
import { Redirect } from 'react-router'

export default function Header() {

    const [menu, setMenu] = useState<boolean>(false);
    const [urlPath, setUrlPath] = useState<any>('');
    const [buid, setBuid] = useState<any>(null);
    const [CONFIG, setCONFIG] = useState<any>({});
    const { slug } = useParams<any>();
    const [logoutStatus, setLogout] = useState<boolean>(false);
    const [STORE_DATA, set_STORE_DATA] = useState<any>({});
    const BUID = sessionStorage.getItem('resto-loyalty-buid');



    const toggleMenu = () => {
        console.log(slug)
        setMenu(!menu);
    }

    const logout = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        sessionStorage.removeItem('loyalty-user-id-' + BUID);
        setLogout(true);
    }



    useEffect(() => {
        let CONFIG_DATA = sessionStorage.getItem('resto-store-config-' + BUID);
        if (CONFIG_DATA) {
            setCONFIG(JSON.parse(CONFIG_DATA));
        }
        let storeData: any = sessionStorage.getItem('resto-store-' + BUID);
        if (storeData) {
            //storeData.StoreBackground = null;
            set_STORE_DATA(JSON.parse(storeData))
        }
        setBuid(atob(slug));
        setUrlPath(window.location.pathname);
    }, []);
    useEffect(() => {
        if (buid) {
            //console.log('BUID=>', buid);

            /* axios({
                method: 'GET',
                url: 'https://devrestoloyalty.restonxt.com/webapi/Authenticate/Authenticate',
                headers: {
                    Authorization: `Bearer APIUser:wXPocq0fG24=`,
                },
            }).then(success => {
                console.log('-----------------------', success)
                if (success && success.data && success.data.data) {
                    return resolve(success.data.data)
                } else {
                    return reject(null)
                }
            }).catch(err => {
                // return reject(null)
            }) */
        }
    }, [buid])






    return (
        <header>
            {logoutStatus ? <Redirect to={`/${slug}/crew/login`} /> : null}
            <Link to={"/" + slug + "/crew"} className={urlPath === "/" + slug + "/crew" ? 'menu active' : 'menu'}>
                <label>QUEUE</label>
                <ImUsers className="icon" />
            </Link>
            <div className="menu">
                {(urlPath === "/" + slug + "/crew/bundles" && CONFIG.EnableBundle) ?
                    <Link to={"/" + slug + "/crew/bundles"} className="menu active">
                        <label>BUNDLES</label>
                        <IoMdGift className="icon" />
                    </Link> : null}

                {(urlPath === "/" + slug + "/crew/credits" && CONFIG.EnableStoreCredit) ?
                    <Link to={"/" + slug + "/crew/credits"} className="menu active">
                        <label>CREDITS</label>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 512 512" width="24px"><path fill="#ffffff" d="M256,73.089c-100.864,0-182.911,82.058-182.911,182.917S155.136,438.911,256,438.911  c100.859,0,182.911-82.046,182.911-182.905S356.86,73.089,256,73.089z M256,410.059c-84.951,0-154.06-69.108-154.06-154.054  c0-84.956,69.109-154.065,154.06-154.065c84.951,0,154.06,69.109,154.06,154.065C410.06,340.951,340.951,410.059,256,410.059z"></path><path fill="#ffffff" d="M227.076,220.157c0-11.572,16.925-13.548,31.606-13.548c13.837,0,32.744,6.485,48.553,14.681l3.098-31.895  c-7.906-4.52-26.247-9.884-44.877-11.005l4.515-32.461H239.77l4.521,32.461c-38.947,3.664-51.651,26.242-51.651,45.154  c0,47.697,88.898,37.547,88.898,66.888c0,11.017-10.434,14.959-28.785,14.959c-24.832,0-43.467-8.74-53.056-17.779l-4.803,35.848  c9.04,5.364,27.375,10.161,49.397,11.294l-4.521,31.329h30.201l-4.515-31.617c45.722-3.954,53.906-28.23,53.906-44.311  C319.363,233.428,227.076,247.532,227.076,220.157z"></path></svg>
                    </Link> : null}

                {(urlPath === "/" + slug + "/crew/history" || urlPath === "/" + slug + "/crew") ?
                    <Link to={"/" + slug + "/crew/history"} className={urlPath === "/" + slug + "/crew/history" ? 'menu active' : 'menu'}>
                        <label>HISTORY</label>
                        <MdHistory className="icon lg" />
                    </Link> : null}
            </div>


            <div className="more-menu">
                <div className="menu more" onClick={toggleMenu}>
                    <label id="username">{STORE_DATA?.BusinessUnitName}</label>
                    <MdKeyboardArrowDown className="icon lg" />
                </div>
                <ul className={menu ? 'show' : ''}>
                    {CONFIG.EnableBundle ?
                        <li>
                            <Link to={"/" + slug + "/crew/bundles"} className="menu">
                                <label>BUNDLES</label>
                                <IoMdGift className="icon" />
                            </Link>
                        </li>
                        : null}
                    {CONFIG.EnableStoreCredit ?
                        <li>
                            <Link to={"/" + slug + "/crew/credits"} className="menu">
                                <label>CREDITS</label>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 512 512" width="24px"><path fill="#ffffff" d="M256,73.089c-100.864,0-182.911,82.058-182.911,182.917S155.136,438.911,256,438.911  c100.859,0,182.911-82.046,182.911-182.905S356.86,73.089,256,73.089z M256,410.059c-84.951,0-154.06-69.108-154.06-154.054  c0-84.956,69.109-154.065,154.06-154.065c84.951,0,154.06,69.109,154.06,154.065C410.06,340.951,340.951,410.059,256,410.059z"></path><path fill="#ffffff" d="M227.076,220.157c0-11.572,16.925-13.548,31.606-13.548c13.837,0,32.744,6.485,48.553,14.681l3.098-31.895  c-7.906-4.52-26.247-9.884-44.877-11.005l4.515-32.461H239.77l4.521,32.461c-38.947,3.664-51.651,26.242-51.651,45.154  c0,47.697,88.898,37.547,88.898,66.888c0,11.017-10.434,14.959-28.785,14.959c-24.832,0-43.467-8.74-53.056-17.779l-4.803,35.848  c9.04,5.364,27.375,10.161,49.397,11.294l-4.521,31.329h30.201l-4.515-31.617c45.722-3.954,53.906-28.23,53.906-44.311  C319.363,233.428,227.076,247.532,227.076,220.157z"></path></svg>
                            </Link>
                        </li>
                        : null}
                    <li>
                        <Link to={"/" + slug + "/crew/history"} className="menu">
                            <label>HISTORY</label>
                            <MdHistory className="icon lg" />
                        </Link>
                    </li>
                    <li>
                        <a href="#logout" className="menu" onClick={logout}>
                            <label>LOGOUT</label>
                            <FiLogOut className="icon" />
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    )
}